import { useContext } from 'react';
import { PageContext } from '../context/PageContext';

export default function useAdminHeaderIsVisible(): Boolean {
  const pageContext = useContext(PageContext);

  if (!pageContext.currentUser || pageContext.currentUser.id === 0) {
    return false;
  }

  return true;
}
