import { FC, useRef } from 'react';
import {
  Box,
  Container,
  Drawer,
  Divider,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { Search } from './Search';
import { MiniMenu } from './MiniMenu';
import { PathPagePropsData } from '../../pages/[...path]';
import { Menu } from './Menu';
import Link from 'next/link';
import { IoMenuOutline } from '@react-icons/all-files/io5/IoMenuOutline';
import { MobileMenu } from './MobileMenu';
import { IoSearch } from '@react-icons/all-files/io5/IoSearch';

interface HeaderProps {
  data: PathPagePropsData;
}

export const Header: FC<HeaderProps> = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const searchRef = useRef(null);
  const {
    isOpen: isOpenSearch,
    onOpen: onOpenSearch,
    onClose: onCloseSearch,
  } = useDisclosure();

  useOutsideClick({
    ref: searchRef,
    handler: () => {
      onCloseSearch();
    },
  });

  const onClickToggleSearch = () => {
    if (isOpenSearch) {
      onCloseSearch();
    } else {
      onOpenSearch();
      // Focus search
      const el = document.querySelector('#mobile-search');

      if (el && el instanceof HTMLInputElement) {
        setTimeout(() => {
          el.focus();
        }, 100);
      }
    }
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton onClick={onClose} />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            {data.mainMenu && (
              <MobileMenu data={data.mainMenu} onClick={onClose} />
            )}
            <Divider borderColor="gray.400" my={4} />
            {data.miniMenu && (
              <MobileMenu
                data={data.miniMenu}
                fontSize="sm"
                onClick={onClose}
              />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box boxShadow="lg" pos="relative" zIndex={9} bg="primary">
        <Box
          ref={searchRef}
          display={{
            base: isOpenSearch ? 'block' : 'none',
            md: 'none',
          }}
          shadow="md"
          pos="absolute"
          top="100%"
          left={0}
          right={0}
          bg="rgba(255,255,255, .6)"
          p={4}
          borderBottom="1px solid"
          borderBottomColor="primary"
          zIndex={12}
        >
          <Search inputId="mobile-search" />
        </Box>
        <Container maxW="container.main">
          <Flex>
            <Flex
              w={{
                base: '80px',
                md: '200px',
              }}
              alignItems="center"
            >
              <Link href="/">
                <a>
                  <img src="/logo_secondary.svg" width="150px" alt="Trabland" />
                </a>
              </Link>
            </Flex>

            <Flex
              display={{
                base: 'flex',
                md: 'none',
              }}
              flex={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton
                onClick={onClickToggleSearch}
                color="white"
                fontSize="2xl"
                variant="ghost"
                aria-label="Menu"
                icon={<Icon as={IoSearch} />}
                mr={4}
              />
              <IconButton
                onClick={onOpen}
                color="white"
                fontSize="3xl"
                variant="ghost"
                aria-label="Menu"
                icon={<Icon as={IoMenuOutline} />}
              />
            </Flex>

            <Flex
              flex={1}
              flexDirection="column"
              pt={4}
              display={{
                base: 'none',
                md: 'flex',
              }}
            >
              <Flex alignItems="center">
                <Search />
                <Box ml={12} mr={-4}>
                  {data.miniMenu && <MiniMenu data={data.miniMenu} />}
                </Box>
              </Flex>
              {data.mainMenu && <Menu data={data.mainMenu} />}
            </Flex>
          </Flex>
        </Container>
      </Box>
    </>
  );
};
