import { gql } from '@apollo/client';

const AutocompleteArticleFragment = gql`
  fragment AutocompleteArticle on NodeArticle {
    url
    tag {
      title
    }
  }
`;

const AutocompleteFarmFragment = gql`
  fragment AutocompleteFarm on NodeFarm {
    title
    url
  }
`;

export const QueryAutocomplete = gql`
  query Autocomplete($text: String!) {
    search(text: $text) {
      total
      items {
        ... on NodeArticle {
          ...AutocompleteArticle
        }
        ... on NodeFarm {
          ...AutocompleteFarm
        }
      }
    }
  }

  ${AutocompleteFarmFragment}
  ${AutocompleteArticleFragment}
`;
