import { FC, useContext } from 'react';
import { Box, BoxProps, Container, Flex, HStack, Icon } from '@chakra-ui/react';
import { PageContext } from '../../context/PageContext';
import useAdminHeaderIsVisible from '../../hooks/useAdminHeaderIsVisible';
import { RiExternalLinkLine } from '@react-icons/all-files/ri/RiExternalLinkLine';
import { IconType } from '@react-icons/all-files';
import backendUrl from '../../utils/backendUrl';
import { FiEdit } from '@react-icons/all-files/fi/FiEdit';
import { FiLogOut } from '@react-icons/all-files/fi/FiLogOut';
import frontendUrl from '../../utils/frontendUrl';
import { useRouter } from 'next/router';

interface AdminHeaderProps {}

type AdminHeaderLinkProps = BoxProps & {
  url: string;
  label: string;
  icon: IconType;
  target?: string;
};

const AdminHeaderLink: FC<AdminHeaderLinkProps> = ({
  url,
  label,
  icon,
  ...props
}) => {
  return (
    <Box
      h="100%"
      display="inline-flex"
      alignItems="center"
      px={4}
      as="a"
      href={url}
      target="_blank"
      _hover={{
        bg: 'gray.900',
      }}
      transition="background .2s"
      {...props}
    >
      {label} <Icon ml={2} as={icon} />
    </Box>
  );
};

export const AdminHeader: FC<AdminHeaderProps> = ({}) => {
  const isVisible = useAdminHeaderIsVisible();
  const pageContext = useContext(PageContext);
  const router = useRouter();

  if (!isVisible) return null;

  return (
    <Box
      boxShadow="lg"
      pos="fixed"
      zIndex={10}
      top={0}
      left={0}
      right={0}
      bg="gray.800"
      h="50px"
      display="flex"
      color="#fff"
    >
      <Container maxW="container.main">
        <Flex h="100%">
          <AdminHeaderLink
            url={backendUrl('/admin/content')}
            label="Administration"
            icon={RiExternalLinkLine}
          />
          {pageContext.entity?.entityType && (
            <AdminHeaderLink
              url={backendUrl(`/node/${pageContext.entity?.id}/edit`)}
              label="Inhalt bearbeiten"
              icon={FiEdit}
              ml={2}
              mr={2}
            />
          )}
          <AdminHeaderLink
            url={backendUrl(`/user/logout`)}
            label="Abmelden"
            icon={FiLogOut}
            ml="auto"
            target="_self"
          />
        </Flex>
      </Container>
    </Box>
  );
};
