import type {
  GetServerSideProps,
  InferGetServerSidePropsType,
  NextPage,
} from 'next';
import { initializeApollo } from '../utils/apolloClient';
import { QueryPage } from '../graphql/PageQuery';
import {
  FilterInputGrouped,
  ImageFragment,
  PageQuery,
  PageQueryVariables,
} from '../generated/types';
import {
  GetServerSidePropsData,
  GetServerSidePropsSettings,
  ImageMap,
} from '../types/global';
import { Base } from '../components/Layout/Base';
import { PageContext } from '../context/PageContext';
import { AppContext } from '../context/AppContext';
import { removeParamsFromPath } from '../utils/urls';
import { queryToFilters } from '../utils/filters';
import { useMemo } from 'react';
import {
  BROWSER_CACHE_TIME,
  CACHE_TAGS_HEADER_NAME,
  GLOBAL_CACHE_TAG,
  STALE_WHILE_REVALIDATE,
  VARNISH_CACHE_TIME,
} from '../config/constants';
import keyBy from 'lodash/keyBy';

type RedirectCodes = 301 | 302 | 303 | 307 | 308;

const recursiveLoopContent = (entity: any, images: ImageFragment[]) => {
  if ('content' in entity) {
    entity.content?.forEach((paragraph: any) =>
      recursiveLoopContent(paragraph, images)
    );
  }

  if ('teasers' in entity) {
    entity.teasers?.forEach((teaser: any) =>
      recursiveLoopContent(teaser, images)
    );
  }

  if ('teaserImage' in entity) {
    if (entity.teaserImage?.copyright) {
      images.push(entity.teaserImage);
    }
  }

  if ('image' in entity) {
    if (entity.image?.copyright) {
      images.push(entity.image);
    }
  }

  if ('images' in entity) {
    entity.images?.forEach((image: any) => {
      if (image.copyright) {
        images.push(image);
      }
    });
  }

  if ('headerImage' in entity) {
    if (entity.headerImage?.copyright) {
      images.push(entity.headerImage);
    }
  }

  return images;
};

const PathPage: NextPage<PathPageProps> = ({ data, settings }) => {
  const images: ImageMap = useMemo(() => {
    const images: ImageFragment[] = [];
    let groupedImages: ImageMap = {};

    if (data?.route) {
      recursiveLoopContent(data.route, images);

      for (const image of images) {
        if (!groupedImages[image.id]) {
          groupedImages[image.id] = [];
        }

        groupedImages[image.id].push(image);
      }
    }

    return groupedImages;
  }, [data]);

  return (
    <AppContext.Provider value={{}}>
      <PageContext.Provider
        value={{
          currentUser: data.currentUser,
          entity: {
            entityType: data.route?.entityType || '',
            url: data.route?.url || '',
            id: data.route?.id || 0,
          },
          config: data.config,
          images,
          adSlots: keyBy(data.adSlotTerms, 'key'),
        }}
      >
        <Base data={data} />
      </PageContext.Provider>
    </AppContext.Provider>
  );
};

export default PathPage;

export type PathPageProps = InferGetServerSidePropsType<
  typeof getServerSideProps
>;

export type PathPagePropsData = PathPageProps['data'];

export const getServerSideProps: GetServerSideProps<
  GetServerSidePropsData<PageQuery>
> = async ({ params, res, query, resolvedUrl, req }) => {
  const cacheTags = new Set<string>();
  const headers: Record<string, string> = {};

  // Pass down cookie to graphql
  if (req.headers.cookie) {
    headers.cookie = req.headers.cookie;
  }

  const client = initializeApollo(null, { cacheTags, headers });

  resolvedUrl = removeParamsFromPath(resolvedUrl, {}, true);

  if (resolvedUrl === '/home') {
    return {
      redirect: {
        destination: '/',
        permanent: true,
      },
    };
  }

  let page = 1;
  let filter: FilterInputGrouped = {};
  let searchQuery = (query.query as string) || '';

  if (query.page) {
    page = parseInt(query.page as string, 10);
  }

  if (query.filters) {
    filter = {
      groups: queryToFilters(query.filters as string),
    };
  }

  if (query.lat && query.lng && query.radius) {
    filter.scopes = [
      {
        name: 'radius_search',
        parameters: [
          {
            name: 'lat',
            value: query.lat as string,
          },
          {
            name: 'lng',
            value: query.lng as string,
          },
          {
            name: 'radius',
            value: `${parseInt(query.radius as string, 10) * 1000}`,
          },
        ],
      },
    ];
  }

  const variables: PageQueryVariables = {
    path: resolvedUrl,
    page,
    filter,
    searchQuery,
  };

  const result = await client.query<PageQuery, PageQueryVariables>({
    query: QueryPage,
    variables,
  });

  res.setHeader(
    'Cache-Control',
    `public, s-maxage=${VARNISH_CACHE_TIME}, max-age=${BROWSER_CACHE_TIME}, stale-while-revalidate=${STALE_WHILE_REVALIDATE}`
  );

  cacheTags.add(GLOBAL_CACHE_TAG);

  if (cacheTags.size) {
    const responseCacheTags: string[] = [];

    cacheTags.forEach((cacheTag) => responseCacheTags.push(cacheTag));

    res.setHeader(CACHE_TAGS_HEADER_NAME, responseCacheTags.join(' '));
  }

  if (result.data?.redirect) {
    if (
      result.data.redirect?.status &&
      result.data.redirect?.status > 300 &&
      result.data.redirect?.status < 400
    ) {
      return {
        redirect: {
          statusCode: result.data.redirect.status as RedirectCodes,
          destination: result.data.redirect.url,
        },
      };
    }
  }

  // TODO: handle 404

  if (result.data?.route?.url) {
    if (result.data?.route?.url !== resolvedUrl) {
      if (result.data.route.url !== '/home') {
        return {
          redirect: {
            permanent: true,
            destination: result.data.route.url,
          },
        };
      }
    }
  }

  if (!result.data?.route) {
    res.statusCode = 404;
  }

  const settings: GetServerSidePropsSettings = {};

  if (req.headers.authorization) {
    settings.auth = req.headers.authorization;
  }

  return {
    props: {
      data: result.data,
      settings,
    },
  };
};
