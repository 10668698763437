import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react';
import { IoSearch } from '@react-icons/all-files/io5/IoSearch';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { useLazyQuery } from '@apollo/client';
import { QueryAutocomplete } from '../../graphql/AutocompleteQuery';
import {
  useCombobox,
  UseComboboxState,
  UseComboboxStateChangeOptions,
} from 'downshift';
import Link from 'next/link';
import { IoChevronForward } from '@react-icons/all-files/io5/IoChevronForward';
import {
  AutocompleteArticleFragment,
  AutocompleteFarmFragment,
  AutocompleteQuery,
  AutocompleteQueryVariables,
} from '../../generated/types';
import { useRouter } from 'next/router';
import { HorseIcon } from '../../theme/Icons/HorseIcon';

interface SearchProps {
  inputId?: string;
}

type AutocompleteItemType =
  | AutocompleteArticleFragment
  | AutocompleteFarmFragment;

export const Search: FC<SearchProps> = ({ inputId }) => {
  const router = useRouter();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [items, setItems] = useState<Array<AutocompleteItemType>>([]);
  const [fetchData, { loading, data, error }] = useLazyQuery<
    AutocompleteQuery,
    AutocompleteQueryVariables
  >(QueryAutocomplete, {});

  useEffect(() => {
    const handleRouteChange = () => {
      setInputValue('');
      selectItem(null);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  const stateReducer = useCallback(
    (
      state: UseComboboxState<AutocompleteItemType | null>,
      actionAndChanges: UseComboboxStateChangeOptions<AutocompleteItemType | null>
    ) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            selectedItem: null,
            inputValue: '',
          };
        default:
          return changes;
      }
    },
    []
  );

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    inputValue,
    setInputValue,
    selectItem,
  } = useCombobox<AutocompleteItemType | null>({
    inputId,
    stateReducer,
    onInputValueChange({ inputValue }) {
      if (inputValue && inputValue.length >= 2) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          fetchData({
            variables: {
              text: inputValue,
            },
          });
        }, 500);
      }
    },
    items,
    itemToString(item) {
      if (!item) return '';

      if (item?.__typename === 'NodeArticle') {
        return item.tag.title;
      }

      return item.title;
    },
  });

  useEffect(() => {
    setItems(data?.search?.items || []);

    if (inputValue.length <= 1) {
      setItems([]);
    }
  }, [data, inputValue]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const isMenuOpen = isOpen && items.length > 0;

  return (
    <Box pos="relative" flex={1}>
      <Flex {...getComboboxProps()}>
        <InputGroup maxW="500px">
          <Input
            bg="white"
            border="0"
            placeholder="Suchen..."
            borderRadius="sm"
            borderBottomRadius={isMenuOpen ? 0 : 'sm'}
            h="46px"
            fontSize="sm"
            pl={4}
            onFocus={() => {
              openMenu();
            }}
            {...getInputProps()}
          />
          <InputRightElement
            mt="3px"
            mr="3px"
            width={inputValue.length > 0 || loading ? '5rem' : '2.5rem'}
            children={
              <>
                {inputValue.length > 0 && !loading && (
                  <IconButton
                    bg="transparent"
                    color="primary"
                    fontSize="xl"
                    borderRadius="sm"
                    aria-label="Search"
                    icon={<IoClose />}
                    onClick={() => {
                      setInputValue('');
                      selectItem(null);
                    }}
                    _hover={{
                      bg: 'transparent',
                      color: 'primaryDark',
                    }}
                    _active={{
                      bg: 'transparent',
                    }}
                  />
                )}
                {loading && (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    w="40px"
                    h="40px"
                    p={2}
                  >
                    <Spinner color="primary" />
                  </Flex>
                )}
                <IconButton
                  bg="secondary"
                  color="white"
                  fontSize="xl"
                  borderRadius="sm"
                  aria-label="Search"
                  icon={<IoSearch />}
                  _hover={{
                    bg: 'secondaryDark',
                  }}
                  _active={{
                    bg: 'secondaryDarker',
                  }}
                />
              </>
            }
          />
        </InputGroup>
        <Box
          {...getMenuProps()}
          pos="absolute"
          top="100%"
          left={0}
          right={0}
          mt="-1px"
          maxW="500px"
          zIndex={12}
        >
          {isMenuOpen && (
            <Box
              bg="#fff"
              border="1px solid"
              borderColor="gray.400"
              borderTopColor="gray.200"
              borderBottomRadius="sm"
              boxShadow="md"
            >
              {items.map((item, index) => (
                <Box key={index} {...getItemProps({ item, index })}>
                  <Link href={item.url} passHref>
                    <Box
                      fontWeight="bold"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      as="a"
                      py={2}
                      color="primaryDark"
                      borderBottomRadius={index === items.length - 1 ? 'xl' : 0}
                      px={4}
                      __css={{
                        bg: highlightedIndex === index && 'gray.100',
                      }}
                    >
                      <Box
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        mr={2}
                      >
                        {item.__typename === 'NodeArticle'
                          ? item.tag.title
                          : item.title}

                        {item.__typename === 'NodeFarm' && (
                          <Icon ml={2} as={HorseIcon} />
                        )}
                      </Box>

                      <Icon as={IoChevronForward} />
                    </Box>
                  </Link>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
};
