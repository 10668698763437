import { FC, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Icon,
  List,
  ListItem,
  ListProps,
  StackDivider,
  StackProps,
  VStack,
} from '@chakra-ui/react';
import Link from 'next/link';
import { MenuFragment, MenuItemFragment } from '../../generated/types';
import { IoChevronDown } from '@react-icons/all-files/io5/IoChevronDown';
import { IoChevronUp } from '@react-icons/all-files/io5/IoChevronUp';

type MobileMenuProps = StackProps & {
  data: MenuFragment;
  onClick?: () => void;
};

interface MobileMenuItemProps {
  data: MenuItemFragment;
  onClick?: () => void;
}

const MobileMenuItem: FC<MobileMenuItemProps> = ({ data, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasLink = data.url && data.url !== '';

  return (
    <Box width="100%">
      <Flex
        alignItems="center"
        justify="space-between"
        width="100%"
        fontWeight="bold"
      >
        {hasLink ? (
          <Link href={data.url} passHref>
            <Box onClick={onClick} flex={1} py={3} as="a" display="block">
              {data.title}
            </Box>
          </Link>
        ) : (
          <Box flex={1} onClick={() => setIsOpen(!isOpen)} py={3} as="span">
            {data.title}
          </Box>
        )}

        {data.children.length > 0 && (
          <Button
            variant="ghost"
            borderLeftWidth="1px"
            borderLeftColor="gray.200"
            borderRadius="none"
            onClick={() => setIsOpen(!isOpen)}
          >
            <Icon as={isOpen ? IoChevronUp : IoChevronDown} />
          </Button>
        )}
      </Flex>

      {isOpen && data.children.length > 0 && (
        <List ml={3}>
          {data.children.map((child, i) => (
            <ListItem key={i}>
              <Link href={child.url} passHref>
                <Flex
                  alignItems="center"
                  px={2}
                  py={2}
                  width="100%"
                  as="a"
                  fontWeight="400"
                  onClick={onClick}
                >
                  {child.title}
                </Flex>
              </Link>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export const MobileMenu: FC<MobileMenuProps> = ({
  data,
  onClick,
  ...props
}) => {
  return (
    <VStack
      align="flex-start"
      divider={<StackDivider />}
      fontSize="md"
      spacing={0}
      {...props}
    >
      {data.items.map((item, i) => (
        <MobileMenuItem data={item} key={i} onClick={onClick} />
      ))}
    </VStack>
  );
};
