import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Container,
  Heading,
  Icon,
} from '@chakra-ui/react';
import { PathPagePropsData } from '../../pages/[...path]';
import { FC } from 'react';
import { Header } from './Header';
import { EntityMapping } from '../../config/entity-mapping';
import { AdminHeader } from './AdminHeader';
import useAdminHeaderIsVisible from '../../hooks/useAdminHeaderIsVisible';
import { Footer } from './Footer';
import Head from 'next/head';
import Link from 'next/link';
import { IoChevronForward } from '@react-icons/all-files/io5/IoChevronForward';
import Script from 'next/script';
import { GA_TRACKING_ID } from '../../config/constants';
import log from '../../utils/log';
import createJsonLd from '../../utils/createJsonLd';

interface BaseProps {
  data: PathPagePropsData;
}

export const Base: FC<BaseProps> = ({ data }) => {
  log('base', data);
  const isAdminHeaderVisible = useAdminHeaderIsVisible();
  const EntityComponent = data.route && EntityMapping[data.route.__typename];

  const jsonLD = createJsonLd(data);

  log('jsonld', jsonLD);

  return (
    <>
      <AdminHeader />

      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(jsonLD),
          }}
        />
      </Head>

      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />

      <Script
        id="gtag"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied'});
            gtag('set', 'ads_data_redaction', true);
                
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />

      <Box mt={isAdminHeaderVisible ? '50px' : 0}>
        <Header data={data} />
        {data.route && 'published' in data.route && !data.route.published && (
          <Box>
            <Container textAlign="center" maxW="container.main" py={9}>
              <Alert
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                status="info"
              >
                <AlertTitle>Information</AlertTitle>
                <AlertDescription>
                  Dieser Inhalt ist nicht veröffentlicht. Er ist nur für
                  eingeloggte Benutzer sichtbar - Gäste sehen diese Seite nicht.
                </AlertDescription>
              </Alert>
            </Container>
          </Box>
        )}
        {EntityComponent && <EntityComponent data={data.route} />}

        {!data.route && (
          <>
            <Head>
              <title>Seite nicht gefunden | Trabland</title>
            </Head>

            <Container textAlign="center" maxW="container.main" py={9}>
              <Heading as="h1" color="primaryDark">
                404 - Seite nicht gefunden
              </Heading>

              <Box my={2}>
                Die angeforderte Seite konnte nicht gefunden werden.
              </Box>
              <Link href="/" passHref>
                <Box as="a" layerStyle="link_button_blue">
                  Zurück zur Startseite <Icon ml={1} as={IoChevronForward} />
                </Box>
              </Link>
            </Container>
          </>
        )}
      </Box>
      <Footer data={data} />
    </>
  );
};
