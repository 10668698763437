import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

export const EntityMapping: Record<string, ComponentType<any>> = {
  /**
   * NODES
   */
  NodeArticle: dynamic(() =>
    import('../components/Node/Article').then((module) => module.Article)
  ),
  NodeHomepage: dynamic(() =>
    import('../components/Node/Homepage').then((module) => module.Homepage)
  ),
  NodeTopic: dynamic(() =>
    import('../components/Node/Topic').then((module) => module.Topic)
  ),
  NodeFarmPage: dynamic(() =>
    import('../components/Node/FarmPage').then((module) => module.FarmPage)
  ),
  NodeSearchPage: dynamic(() =>
    import('../components/Node/SearchPage').then((module) => module.SearchPage)
  ),
  NodeFarm: dynamic(() =>
    import('../components/Node/Farm').then((module) => module.Farm)
  ),
  NodePerson: dynamic(() =>
    import('../components/Node/Person').then((module) => module.Person)
  ),

  /**
   * TEASER
   */
  Teaser_image_title: dynamic(() =>
    import('../components/Teaser/TeaserImageTitle').then(
      (module) => module.TeaserImageTitle
    )
  ),
  Teaser_image_title_text_blue: dynamic(() =>
    import('../components/Teaser/TeaserImageTitleText').then(
      (module) => module.TeaserImageTitleText
    )
  ),
  Teaser_image_title_text_gold: dynamic(() =>
    import('../components/Teaser/TeaserImageTitleText').then(
      (module) => module.TeaserImageTitleText
    )
  ),
  Teaser_list: dynamic(() =>
    import('../components/Teaser/TeaserList').then(
      (module) => module.TeaserList
    )
  ),

  /**
   * PARAGRAPHS
   */
  ParagraphText: dynamic(() =>
    import('../components/Paragraph/Text').then((module) => module.Text)
  ),
  ParagraphInfoBox: dynamic(() =>
    import('../components/Paragraph/InfoBox').then((module) => module.InfoBox)
  ),
  ParagraphImage: dynamic(() =>
    import('../components/Paragraph/Image').then((module) => module.Image)
  ),
  ParagraphTextWithImage: dynamic(() =>
    import('../components/Paragraph/TextWithImage').then(
      (module) => module.TextWithImage
    )
  ),
  ParagraphSection: dynamic(() =>
    import('../components/Paragraph/Section').then((module) => module.Section)
  ),
  ParagraphSectionWithTextImage: dynamic(() =>
    import('../components/Paragraph/SectionWithTextImage').then(
      (module) => module.SectionWithTextImage
    )
  ),
  ParagraphHero: dynamic(() =>
    import('../components/Paragraph/Hero').then((module) => module.Hero)
  ),
  ParagraphTextHero: dynamic(() =>
    import('../components/Paragraph/TextHero').then((module) => module.TextHero)
  ),
  ParagraphTeaserList: dynamic(() =>
    import('../components/Paragraph/TeaserList').then(
      (module) => module.TeaserList
    )
  ),
  ParagraphLinks: dynamic(() =>
    import('../components/Paragraph/Links').then((module) => module.Links)
  ),
  ParagraphSearchResult: dynamic(() =>
    import('../components/Paragraph/SearchResult').then(
      (module) => module.SearchResult
    )
  ),
  ParagraphFaq: dynamic(() =>
    import('../components/Paragraph/Faq').then((module) => module.Faq)
  ),
  ParagraphComment: dynamic(() =>
    import('../components/Paragraph/Comment').then((module) => module.Comment)
  ),
  ParagraphFromLibrary: dynamic(() =>
    import('../components/Paragraph/FromLibrary').then(
      (module) => module.FromLibrary
    )
  ),
  ParagraphFormular: dynamic(() =>
    import('../components/Paragraph/Formular').then((module) => module.Formular)
  ),
  ParagraphFarmFarmCategories: dynamic(() =>
    import('../components/Paragraph/FarmFarmCategories').then(
      (module) => module.FarmFarmCategories
    )
  ),
  ParagraphCommentListAndFormular: dynamic(() =>
    import('../components/Paragraph/CommentListAndFormular').then(
      (module) => module.CommentListAndFormular
    )
  ),
  ParagraphHighlightedRating: dynamic(() =>
    import('../components/Paragraph/HighlightedRating').then(
      (module) => module.HighlightedRating
    )
  ),
  ParagraphFocusTagGlossary: dynamic(() =>
    import('../components/Paragraph/FocusTagGlossary').then(
      (module) => module.FocusTagGlossary
    )
  ),
  ParagraphFarmTeaserList: dynamic(() =>
    import('../components/Paragraph/FarmTeaserList').then(
      (module) => module.FarmTeaserList
    )
  ),
  ParagraphFileDownload: dynamic(() =>
    import('../components/Paragraph/FileDownload').then(
      (module) => module.FileDownload
    )
  ),
  ParagraphAdSlot: dynamic(() =>
    import('../components/Paragraph/AdSlot').then((module) => module.AdSlot)
  ),

  /**
   * FORMULARS
   */
  Formular_contact: dynamic(() =>
    import('../components/Forms/ContactForm').then(
      (module) => module.ContactForm
    )
  ),
  Formular_comment: dynamic(() =>
    import('../components/Forms/CommentForm').then(
      (module) => module.CommentForm
    )
  ),
};
