import {
  Box,
  Container,
  Flex,
  Icon,
  Img,
  Stack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { PathPagePropsData } from '../../pages/[...path]';
import Link from 'next/link';
import { PageContext } from '../../context/PageContext';
import { IoLocation } from '@react-icons/all-files/io5/IoLocation';
import { HiPhone } from '@react-icons/all-files/hi/HiPhone';
import { IoMailOutline } from '@react-icons/all-files/io5/IoMailOutline';
import scrollToElement from '../../utils/scrollToElement';

interface FooterProps {
  data: PathPagePropsData;
}

interface FooterMenuItemProps {
  url: string;
  label: string;
}

const FooterMenuItem: FC<FooterMenuItemProps> = ({ url, label }) => {
  return (
    <Link href={url} passHref>
      <Box
        as="a"
        py={1.5}
        _hover={{
          textDecoration: 'underline',
        }}
      >
        {label}
      </Box>
    </Link>
  );
};

export const Footer: FC<FooterProps> = ({ data }) => {
  const context = useContext(PageContext);

  return (
    <Box
      bg="primary"
      pt={{
        base: 16,
        md: 32,
      }}
      color="#fff"
      pos="relative"
      mt={12}
    >
      <Img
        src="/bottom_wave.svg"
        width="100%"
        pos="absolute"
        top="-1px"
        left={0}
        right={0}
      />
      <Container maxW="container.main">
        <Flex
          flexDirection={{
            base: 'column',
            md: 'row',
          }}
        >
          <Box
            mr={{
              base: 0,
              md: 24,
            }}
            mb={{
              base: 4,
              md: 0,
            }}
            flexShrink={0}
          >
            <Img
              src="/logo_footer.svg"
              width="250px"
              mx={{
                base: 'auto',
                md: 0,
              }}
            />
          </Box>
          <Stack
            direction={{
              base: 'column',
              md: 'row',
            }}
            spacing={12}
            mr={20}
          >
            {data.footerMenu?.items?.map((item, i) => {
              return (
                <Box key={i}>
                  <Box
                    fontFamily="heading"
                    fontWeight="bold"
                    fontSize="lg"
                    pb={2}
                  >
                    {item.title}
                  </Box>
                  <Box h="3px" bg="white" w="55px" />
                  <Flex flexDir="column" pt={4}>
                    {item.children?.map((child, j) => (
                      <FooterMenuItem
                        key={j}
                        url={child.url}
                        label={child.title}
                      />
                    ))}
                  </Flex>
                </Box>
              );
            })}
          </Stack>
          {context?.config && (
            <Box
              mt={{
                base: 9,
                md: 0,
              }}
            >
              <Box fontFamily="heading" fontWeight="bold" fontSize="lg" pb={2}>
                KONTAKT
              </Box>
              <Box h="3px" bg="white" w="55px" />
              <Flex flexDir="column" pt={4}>
                {context.config.address && (
                  <Flex alignItems="center" my={2}>
                    <Icon fontSize="2xl" as={IoLocation} mr={2} />
                    {context.config.address}
                  </Flex>
                )}

                {context.config?.phone && (
                  <Flex alignItems="center" my={2}>
                    <Icon fontSize="2xl" as={HiPhone} mr={2} />
                    {context.config.phone}
                  </Flex>
                )}

                {context.config.email && (
                  <Flex alignItems="center" my={2}>
                    <Icon fontSize="2xl" as={IoMailOutline} mr={2} />
                    {context.config.email}
                  </Flex>
                )}
              </Flex>
              <Box fontSize="xs">
                Trabland.de ist der Betreiber dieses Internetportals und kein
                Vermittler.
                <br />
                Bitte verwenden Sie unsere Kontaktdaten NICHT für Anfragen und
                Mitteilungen an Reiterhöfe und Reitbetriebe. Nutzen Sie
                stattdessen bitte die Kontaktdaten dieser Betriebe.
                <br />
                <br />
                Anfragen über die Trabland-Kontaktdaten werden nicht an die
                Betriebe weitergeleitet.
              </Box>
            </Box>
          )}
        </Flex>
        {context.images && Object.keys(context.images).length > 0 && (
          <Flex alignItems="flex-start" fontSize=".65rem" mt={8}>
            Fotos:
            <Wrap ml={2}>
              {Object.keys(context.images).map((imageId, i) => (
                <WrapItem key={i}>
                  © {context.images![imageId][0].copyright}{' '}
                  {context.images![imageId].map((image, j) => {
                    return (
                      <Box
                        key={j}
                        cursor="pointer"
                        onClick={() => {
                          scrollToElement(`.image-${image.id}`, j);
                        }}
                        pos="relative"
                        ml={1}
                        top="-2px"
                        fontSize=".65rem"
                      >
                        #{j + 1}
                      </Box>
                    );
                  })}
                  {i !== Object.keys(context.images!).length - 1 && ','}
                </WrapItem>
              ))}
            </Wrap>
          </Flex>
        )}
      </Container>
      <Box
        mt={12}
        borderTop="1px solid"
        borderTopColor="rgba(255,255,255,.3)"
        py={6}
      >
        <Container maxW="container.main" textAlign="center">
          Copyright © 2022 Trabland GmbH
        </Container>
      </Box>
    </Box>
  );
};
