import { PathPageProps, PathPagePropsData } from '../pages/[...path]';

const LOCAL_BUSINESS = {
  '@type': 'LocalBusiness',
  name: 'Trabland GmbH',
  image: '',
  '@id': '',
  url: 'https://www.trabland.de/',
  telephone: '+49 (0)561 400 85 284',
  email: 'info@trabland.de',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Werner-Henschel-Straße 2',
    addressLocality: 'Fuldatal',
    postalCode: '34233',
    addressCountry: 'DE',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 51.3215952,
    longitude: 9.503079999999999,
  },
};

export default function createJsonLd(data: PathPagePropsData) {
  const elements: object[] = [];

  if (data.route?.__typename === 'NodeArticle') {
    data.route.structuredData.forEach((paragraph) => {
      if (paragraph.__typename === 'ParagraphFaq') {
        const questions: object[] = [];
        paragraph.questions.forEach((question) => {
          questions.push({
            '@type': 'Question',
            name: question.question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: question.answer,
            },
          });
        });

        elements.push({
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: questions,
        });
      } else if (paragraph.__typename === 'ParagraphHowTo') {
        elements.push({
          '@type': 'HowTo',
          name: paragraph.headline,
          tool: paragraph.tools?.map((tool) => ({
            '@type': 'HowToTool',
            name: tool,
          })),
          step: paragraph.steps?.map((step) => ({
            '@type': 'HowToStep',
            text: step,
          })),
        });
      }
    });
  }

  return {
    '@context': 'https://schema.org',
    '@graph': [LOCAL_BUSINESS, ...elements],
  };
}
