import { FC } from 'react';
import { Box, Flex, HStack, Icon, List, ListItem } from '@chakra-ui/react';
import Link from 'next/link';
import { IoChevronDown } from '@react-icons/all-files/io5/IoChevronDown';
import { IoChevronForward } from '@react-icons/all-files/io5/IoChevronForward';
import { MenuFragment } from '../../generated/types';

interface MenuProps {
  data: MenuFragment;
}

export const Menu: FC<MenuProps> = ({ data }) => {
  return (
    <Box as="nav" fontSize="md" mt={2}>
      <HStack
        mx={-4}
        as="ul"
        listStyleType="none"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        {data.items.map((item, i) => {
          const hasChildren = item.children.length > 0;
          const isLast = i === data.items.length - 1;
          const itemHasLink = item.url && item.url !== '';

          const InnerItem = (
            <Flex
              alignItems="center"
              px={4}
              py={4}
              as={itemHasLink ? 'a' : 'span'}
              fontWeight="bold"
            >
              {item.title} {hasChildren && <Icon ml={1} as={IoChevronDown} />}
            </Flex>
          );

          return (
            <Box
              as="li"
              pos="relative"
              key={i}
              color="white"
              borderTopRadius="sm"
              transition="none"
              __css={{
                _hover: {
                  bg: 'primaryDark',
                  '.menu-dropdown': {
                    display: 'block',
                  },
                },
              }}
            >
              {itemHasLink ? (
                <Link href={item.url} passHref>
                  {InnerItem}
                </Link>
              ) : (
                InnerItem
              )}

              {hasChildren && (
                <Box
                  zIndex={9}
                  className="menu-dropdown"
                  display="none"
                  border="2px solid"
                  borderColor="primary"
                  pos="absolute"
                  left={isLast ? 'auto' : '0'}
                  right={isLast ? '0' : 'auto'}
                  top="100%"
                  bg="white"
                  color="primary"
                  shadow="lg"
                  minW="200px"
                  borderBottomRadius="sm"
                  borderTopRightRadius={isLast ? 'none' : 'sm'}
                  borderTopLeftRadius={isLast ? 'sm' : 'none'}
                >
                  <List fontSize="md">
                    {item.children.map((child, i) => {
                      return (
                        <ListItem key={i}>
                          <Link href={child.url} passHref>
                            <Flex
                              borderTopRightRadius={
                                i === 0 && !isLast ? 'sm' : 'none'
                              }
                              borderTopLeftRadius={
                                i === 0 && isLast ? 'sm' : 'none'
                              }
                              borderBottomRadius={
                                i === item.children.length - 1 ? 'sm' : 'none'
                              }
                              px={4}
                              py={2}
                              alignItems="center"
                              as="a"
                              whiteSpace="nowrap"
                              _hover={{
                                bg: 'gray.100',
                                color: 'primaryDark',
                              }}
                            >
                              {child.title}
                            </Flex>
                          </Link>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              )}
            </Box>
          );
        })}
      </HStack>
    </Box>
  );
};
