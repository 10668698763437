import { createContext } from 'react';
import {
  AdSlotFragment,
  ConfigFragment,
  ImageFragment,
  UserFragment,
} from '../generated/types';
import { ImageMap } from '../types/global';

interface PageContextType {
  currentUser?: UserFragment | null;
  entity?: {
    entityType: string;
    url: string;
    id: number;
  };
  config?: ConfigFragment | null;
  images?: ImageMap;
  adSlots?: Record<string, AdSlotFragment>;
}

export const PageContext = createContext<PageContextType>({});
