import { FC } from 'react';
import { MenuFragment } from '../../generated/types';
import { Box, HStack } from '@chakra-ui/react';
import Link from 'next/link';

interface MiniMenuProps {
  data: MenuFragment;
}

export const MiniMenu: FC<MiniMenuProps> = ({ data }) => {
  return (
    <HStack as="ul" listStyleType="none" color="primary">
      {data.items.map((item, i) => (
        <Box as="li" key={i}>
          <Link href={item.url} passHref>
            <Box
              px={4}
              py={3}
              as="a"
              fontWeight="bold"
              borderRadius="lg"
              color="white"
              _hover={{
                bg: 'primaryDark',
              }}
            >
              {item.title}
            </Box>
          </Link>
        </Box>
      ))}
    </HStack>
  );
};
