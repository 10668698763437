export default function scrollToElement(
  selector: string,
  index: number | null = null
) {
  let element;

  if (index === null) {
    element = document.querySelector(selector);
  } else {
    const elements = document.querySelectorAll(selector);
    element = elements.item(index);
  }

  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
